import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Box } from '../components/Layout';
import colors from '../utils/colors';
import PageWrapper from '../components/PageWrapper';
import PostFeedList from '../components/CMS/postFeedList';
import { MetaData } from '../components/common/meta';
import SubNav from '../components/Navigation/subNav';
import PostFeedHeader from '../components/CMS/postFeedHeader';
/**
* Main index page (home page)
*
* Loads all posts from Ghost and uses pagination to navigate through them.
* The number of posts that should appear per page can be setup
* in /utils/siteConfig.js under `postsPerPage`.
*
*/
const Tag = ({ data, location, pageContext }) => {
    const posts = data.allGhostPost.edges.map((post) => {
        //console.log('tag:' + post.node.slug);
        return post.node;
    })
    var headerPage = null;
    if (data.allGhostPage.edges.length > 0)
        headerPage = data.allGhostPage.edges[0].node;

    if (headerPage){
        posts.forEach((post, index ) => {
            if (!post.feature_image) {
                var postCatTag = post.tags.find(tag=> tag.feature_image);
                if (postCatTag)
                    post.feature_image = postCatTag.feature_image;
            }            
        });

        
    }

    return (
        <>
            <MetaData location={location} />

            <PageWrapper>
                <>
                <Box
                    width={[1, 1, 2 / 3]}
                    minWidth={[0,0,'1024px']}
                    m={['4rem 0 1.5rem 0', '4rem 0 1.5rem 0', '4rem auto 1.5rem auto']}>    
                    <SubNav/>
                    <Box
                    mt={['1.5rem', '1.5rem', '1.5rem']} mb={['1rem', '1rem', '1rem']}
                    color={colors.secondary}
                    >
                        {headerPage?<PostFeedHeader page={headerPage} totalPosts={data.allGhostPost.totalCount} />: <h1>{data.allGhostTag.edges[0].node.name}</h1>}
                        
                    </Box>
                    <PostFeedList hideImage={true} posts={posts} pageContext={pageContext}/>
                </Box>
                </>
            </PageWrapper>
        </>
    )
}

Tag.propTypes = {
    data: PropTypes.shape({
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Tag

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query tagQuery($limit: Int!, $skip: Int!, $slug: String!) {
    allGhostTag(filter: {slug: {eq: $slug}}) {
        edges {
            node {
              name
            }
          }
      }      
    allGhostPost(filter: {tags: {elemMatch: {slug: {eq: $slug}}}},
        sort: { order: DESC, fields: [published_at] },
        limit: $limit,
        skip: $skip
    ) {
        totalCount
      edges {
        node {
            tags {
                slug
                name
                visibility
                feature_image
            }
            excerpt
            feature_image
            title
            slug
            published_at
            reading_time
        }
      }
    }
    allGhostPage(filter: {tags: {elemMatch: {slug: {eq: $slug}}}}) {
        edges {
            node {
                title
                feature_image
                custom_excerpt
            }
        }
    }    

  }
`
