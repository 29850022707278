import React from 'react';
import colors from '../../utils/colors';

class PostFeedHeader extends React.Component {

    render() {
        console.log('totalPosts: ' + this.props.totalPosts);
        return (
            <div >

                <div style={{
                    backgroundImage: `url(${this.props.page.feature_image})`,
                    backgroundPosition: 'center',
                    backgroundColor: colors.secondary, 
                    backgroundSize: 'cover',
                    position: 'relative', 
                    height:'300px', 
                    width:'100%',
                    marginBottom: '20px'}}>
                        <div style={{position: 'absolute', 
                            top: 30, 
                            left: 0, 
                            width: '100%',
                            textAlign: "center",
                            color: colors.primary}}>
                                <h1 style={{color: colors.primary}}>{this.props.page.title}</h1>
                                <p style={{color: colors.primary}}>Total {this.props.totalPosts} Posts</p>
                                <p style={{color: colors.primary}}>{this.props.page.custom_excerpt}</p>
                        </div>
                </div>                

            </div>
          );
    }
}
    
export default PostFeedHeader;